import {Link} from 'react-router-dom' ;
import {useAuth} from  '../context/AuthProvider' ;
import Header from '../components/Header' ;
import Footer from '../components/Footer' ;
import appImages from '../images/AppImages.png' ;
import appleDownloadButton from '../images/AppleDownloadButton.png' ;
import androidDownloadButton from '../images/AndroidDownloadButton.png' ;
import '../Main.css' ;
import './Download.css';

const Download = () => {
	return (
		<div className='outerContainer'>
			<Header/>
			<div className='innerContainer'>
				<h1>Download IDLmeals</h1>
				<p>Coming soon...</p>
				<Link to="/about"><img src={appImages} alt="IDLmeals"  style={{width: '1000px',height:'600px',margin: '10px 0 50px 0' }}/></Link>
				<p><Link to="https://www.apple.com/store"><img src={appleDownloadButton} alt="Apple Download"  style={{ width: '450px', height: '150px', margin: ' 10px 0 50px 0' }}/></Link></p>
				<p><Link to="https://play.google.com/store"><img src={androidDownloadButton} alt="Android Download"  style={{ width: '450px', height: '150px', margin: ' 10px 0 50px 0' }}/></Link></p>
				<Footer/>
			</div>
		</div>
	) ;
} ;

export default Download ;
