import { Link } from 'react-router-dom';
import {useAuth} from  '../context/AuthProvider';
import Header from '../components/Header';
import Footer from '../components/Footer' ;
import '../Main.css' ;
import './Contact.css';

const Contact = () => {
	return (
		<div className='outerContainer'>
			<Header/>
			<div className='innerContainer'>
				<h1>Contact IDLmeals</h1>
				<Footer/>
			</div>
		</div>
	) ;
} ;

export default Contact ;
