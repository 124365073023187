import {Link} from 'react-router-dom' ;
import {useAuth} from  '../context/AuthProvider' ;
import Header from '../components/Header' ;
import Footer from '../components/Footer' ;
import '../Main.css' ;
import './About.css';


const About = () => {
	return (
		<div className='outerContainer'>
			<Header/>
			<div className='innerContainer'>
				<h1>About IDLmeals</h1>
				<p>IDLmeals is poised to revolutionize the way college students transition from dorm life to off-campus housing by making cooking simple, budget-friendly, and accessible to all. Our aim is to create nutrition resources that cater to the unique needs and challenges of college students, making their culinary journey a delightful experience.</p>
				<p>With IDLmeals, our vision is to empower college students everywhere to prepare delicious and nutritious meals effortlessly by leveraging user-provided information to generate customized, low-budget recipes that match the utensils available and accommodate any specific dietary restrictions or disabilities.</p>
				<p>Our team is developing a comprehensive recipe database in collaboration with students in the Nutrition department, and innovative algorithms that adapt to individual preferences. We envision a community-driven platform where users can share their own easy recipes and cooking tips, fostering a supportive environment for culinary growth.</p>
				<p>IDLmeals will not only simplify cooking but also promote independence, creativity, and healthy eating among college students, setting them up for success in their off-campus adventures. Together, we'll build a tool that transforms cooking from a chore into a rewarding and enjoyable experience for our target audience.</p>
				<Footer/>
			</div>
		</div>
	) ;
} ;

export default About ;
