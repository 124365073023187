import React from 'react' ;
import {Link} from 'react-router-dom' ;
import '../Main.css' ;
import './Footer.css' ;

function Footer() {
	return (
		<p className='footer'>Copyright &copy; <Link to="/">IDLmeals LLC</Link>. All rights reserved.</p>
	)
}

export default Footer ;
