import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import axios from '../api/axios';
import {useAuth} from  '../context/AuthProvider';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../Main.css';
import './UserCreate.css';

const UserCreate = () => {
	const [formData, setFormData] = useState({
		nutritionUserId: '',
		firstName: '',
		lastName: '',
		adminLevel: '',
		email: '',
		password: '',
		});
	const { auth } = useAuth() ;
	const [submitted, setSubmitted] = useState(false);
      const config = {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
        },
      };
	const handleSubmit = async (event) => {
		event.preventDefault();
        axios.post(`/nutritionuser`,handleSendData(formData), config)
          .then(response => {
            if(response.status===200){
              console.log('New user submitted successfully!');
            setTimeout(() => {
                setSubmitted(false);
              }, 3000);
              window.location.href = '/user/list' ;
            } else {
              console.error(
                'Failed to submit new user:',
                response.status,
                response.statusText
              );
            }
          })
          .catch (error => {
            console.error('Error adding user:', error);
          })
      };
      const handleSendData = (formData) => {
        // Format the data before sending
        const formattedData = {
          nutritionUserId: formData.nutritionUserId,
          firstName: formData.firstName,
          lastName: formData.lastName,
          adminLevel: formData.adminLevel,
          email: formData.email,
          password: formData.password,
        };
    
        // Now, send the formattedData to the server
        // Your API call or fetch code goes here
        console.log(formattedData); // For testing
        return formattedData;
      };
          const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      };
	return (
		<div className='outerContainer'>
			<Header/>
			<div className='innerContainer'>
				<h1>Add Student Account</h1>
				<form onSubmit={handleSubmit} className='form'>
					<div className='form-group'>
						<label htmlFor="nutritionUserId">TCU Student ID:</label>
						<input type="number" name="nutritionUserId" value={formData.nutritionUserId} onChange={handleChange} required pattern="\d+" title="Please enter a valid TCU ID." className="integer-input"/>
					</div>
					<div className='form-group'>
						<label htmlFor="firstName">First Name:</label>
						<input type="text" name="firstName" value={formData.firstName} onChange={handleChange} required/>
					</div>
					<div className='form-group'>
						<label htmlFor="lastName">Last Name:</label>
						<input type="text" name="lastName" value={formData.lastName} onChange={handleChange} required/>
					</div>
					<label>Admin Level: <br />
						<select name='adminLevel' value={formData.adminLevel} onChange={handleChange} required>
							<option value="" disabled>Select</option>
							<option value="student">Student</option>
							<option value="admin">Admin</option>
						</select>
					</label>
					<div className='form-group'>
						<label htmlFor="email">Email:</label>
						<input type="text" name="email" value={formData.email} onChange={handleChange} required/>
					</div>
					<div className='form-group'>
						<label htmlFor="password">Password:</label>
						<input type="text" name="password" value={formData.password} onChange={handleChange} required/>
					</div>
					<button type="submit" className='button'>Add Student</button>
					{submitted && (
						<div className='success-banner'>Student submitted successfully!</div>
					)}
					<Link to="/list/user" className='view-all-button'>View All Users</Link>
				</form>
				<Footer/>
			</div>
		</div>
	)
}

export default UserCreate ;
