import React, {useState, useEffect} from 'react';
import {useParams, Link} from 'react-router-dom';
import Header from '../components/Header' ;
import Footer from '../components/Footer' ;
import axios from '../api/axios';
import {useAuth} from  '../context/AuthProvider' ;
import '../Main.css' ;
import './Dashboard.css';

const Dashboard = () => {
  const [recipes, setRecipes] = useState([]);

  const { auth } = useAuth()
  console.log("printing auth information")
  console.log(auth.user)
  console.log(auth.roles)
  console.log(auth.accessToken)
  console.log(auth.id)


  const config = {
    headers: {
      Authorization: `Bearer ${auth.accessToken}`,
    },
  };

  useEffect(() => {
    fetchAllRecipes();
  }, []);

  const fetchAllRecipes = () => {
    axios.get(`/recipes/nutrition-user/${auth.id}`, config)
      .then(response => {
        const data = response.data;
        if (data && Array.isArray(data.data)) {
          setRecipes(data.data);
        } else {
          console.error('Data received does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };

  const renderTableData = () => {
    return recipes.map((recipe, index) => {
      const { recipeId, title, status } = recipe;
      return (
        <tr key={recipeId}>
          <td>
          <Link to={`/recipe/${recipe.recipeId}`}>{title}</Link>
          </td>
          <td>{status}</td>
        </tr>
      );
    });
  };

  const renderTableHeader = () => {
    if (recipes.length === 0) {
      return null;
    }
    return (
      <tr>
        <th>Title</th>
        <th>Status</th>
      </tr>
    );
  };

  return (
    <div className='outerContainer'>
      <Header/>
      <div className='innerContainer'>
        <h1>Your Recipe Table</h1>
        <table id='recipes'>
          <thead>{renderTableHeader()}</thead>
          <tbody>{renderTableData()}</tbody>
        </table>
				<Footer/>
      </div>
    </div>
  );
};

export default Dashboard ;
