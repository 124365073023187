import { Link } from 'react-router-dom';
import axios from '../api/axios';
import {useAuth} from  '../context/AuthProvider';
import Header from '../components/Header';
import Footer from '../components/Footer' ;
import appImages from '../images/AppImages.png' ;
import '../Main.css' ;
import './Home.css' ;

const Home = () => {
	return (
		<div className='outerContainer'>
			<Header/>
			<div className='innerContainer'>
				<h1>IDLmeals</h1>
				<Link to="/about"><img src={appImages} alt="IDLmeals"  style={{width: '1000px',height:'600px',margin: '10px 0 50px 0' }}/></Link>
				<Footer/>
			</div>
		</div>
	) ;
} ;

export default Home ;
