import React from 'react' ;
import {Link} from 'react-router-dom' ;
import {useAuth} from  '../context/AuthProvider' ;
import idlMealsLogo from '../images/IdlMealsLogo.png' ;
import '../Main.css' ;
import './Header.css' ;

function Header() {
	const {auth} = useAuth()
	const isUser = auth ;
	const isAdmin = auth && auth.roles === 'admin' ;
  return (
        <div className='headerContainer'>
        <Link to="/"><img src={idlMealsLogo} alt="IDLmeals"  style={{ width: '150px', height: '105px', margin: ' 10px 0 50px 0' }}/></Link>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/about">About</Link></li>
            <li><Link to="/download">Download</Link></li>
            <li><Link to="/recipe/list">Recipes</Link></li>
            {isAdmin && (
            	<div>
              		<li><Link to="/user/list">List Users</Link></li>
              		<li><Link to="/user/create">Add User</Link></li>
              	</div>
            )}
            <li><Link to="/team">Team</Link></li>
            <li><Link to="/contact">Contact</Link></li>
            {isUser && (
            	<div>
		            <li><Link to="/dashboard">Dashboard</Link></li>
		            <li><Link to="/recipe/create">Suggest Recipe</Link></li>
              	</div>
            )}
            {!isUser && (
            	<div>
		            <li><Link to="/login">Login</Link></li>
              	</div>
            )}
          </ul>
          <Link to="/dashboard"><h3 className='userName'>{auth.name}</h3></Link>
        </div>
  )
}

export default Header ;
