import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import axios from '../api/axios';
import {useAuth} from  '../context/AuthProvider';
import Header from '../components/Header';
import Footer from '../components/Footer' ;
import '../Main.css' ;
import './UserList.css' ;

const UserList = () => {
  const [userList, setUserList] = useState([]);
  const { auth } = useAuth()
  console.log("printing auth information")
  console.log(auth.user)
  console.log(auth.roles)
  console.log(auth.accessToken)
  const config = {
    headers: {
      Authorization: `Bearer ${auth.accessToken}`,
    },
  };
  useEffect(() => {
    fetchUserList();
  }, []);
  const fetchUserList = () => {
    axios.get('/nutritionuser', config)
      .then(response => {
        const data = response.data;
        if (data && Array.isArray(data.data)) {
          setUserList(data.data);
        } else {
          console.error('Data received does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };
  const renderTableData = () => {
    return userList.map((user) => {
      const { nutritionUserId, firstName, lastName, email, adminLevel } = user;
      return (
          <tr key={nutritionUserId}>
              <td>
              <Link to={`/user/${nutritionUserId}`}>{firstName}</Link>
              </td>
              <td>
              <Link to={`/user/${nutritionUserId}`}>{lastName}</Link></td>
              <td>
              <Link to={`/user/${nutritionUserId}`}>{email}</Link>
              </td>
              <td>
                <Link to={`/user/${nutritionUserId}`}>{adminLevel}</Link>
              </td>
          </tr>
      );
    });
  };
  const renderTableHeader = () => {
    if (userList.length === 0) {
      return null;
    }
    return (
      <tr>
        <th>First Name</th>
        <th>Last name</th>
        <th>Email</th>
        <th>Admin Level</th>
      </tr>
    );
  };

  return (
    <div className='outerContainer'>
      <Header/>
      <div className='innerContainer'>
        <h1>All Users </h1>
        <table className='userTable' id='userList'>
          <thead>{renderTableHeader()}</thead>
          <tbody>{renderTableData()}</tbody>
        </table>
        <Link to="/user/create" className='manage-button'>Add a User</Link>
				<Footer/>
      </div>
    </div>
  );
};

export default UserList;
