import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import React from 'react';
import About from './content/About';
import Contact from './content/Contact';
import Download from './content/Download';
import Home from './content/Home';
import Team from './content/Team';
import Login from './components/Login';
import RecipeCreate from './recipe/RecipeCreate';
import RecipeList from './recipe/RecipeList';
import RecipeListUser from './recipe/RecipeListUser';
import RecipeUpdate from './recipe/RecipeUpdate';
import RecipeView from './recipe/RecipeView';
import UserList from './user/UserList';
import UserCreate from './user/UserCreate';
import Dashboard from './dashboard/Dashboard';
import {AuthProvider, useAuth} from './context/AuthProvider.js'; // Import useAuth
import './Main.css' ;
import './App.css';

const PrivateRoute = ({ element }) => {
  const { auth } = useAuth();
  return auth.isAuthenticated ? element : <Navigate to="/login" />;
};

const App = () => {

  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path='/login' element={<Login />} />
          <Route path='/' element={<Home/>}/>
          <Route path='/about' element={<About/>}/>
          <Route path='/download' element={<Download/>}/>
          <Route path='/team' element={<Team/>}/>
          <Route path='/contact' element={<Contact/>}/>
          <Route path='/dashboard' element={<PrivateRoute element={<Dashboard/>}/>}/>
          <Route path='/recipe' element={<RecipeList/>}/>
          <Route path='/recipe/list' element={<RecipeList/>}/>
          <Route path='/recipe/:userId/list' element={<PrivateRoute element={<RecipeListUser/>}/>}/>
          <Route path='/recipe/create' element={<PrivateRoute element={<RecipeCreate/>}/>}/>
          <Route path='/recipe/:recipeId' element={<PrivateRoute element={<RecipeView/>}/>}/>
          <Route path='/recipe/:recipeId/view' element={<PrivateRoute element={<RecipeView/>}/>}/>
          <Route path='/recipe/:recipeId/edit' element={<PrivateRoute element={<RecipeUpdate/>}/>}/>
          <Route path='/user/list' element={<PrivateRoute element={<UserList/>}/>}/>
          <Route path='/user/create' element={<PrivateRoute element={<UserCreate/>}/>}/>
        </Routes>
      </Router>
    </AuthProvider>
  ) ;
} ;

export default App ;
