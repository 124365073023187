import {Link} from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer' ;
import linkedInLogo from '../images/LinkedinLogo.png' ;
import michaelDiCensoImage from '../images/MichaelDiCenso.jpg' ;
import samanthaDavisImage from '../images/SamanthaDavis.jpg' ;
import edIpserImage from '../images/EdIpser.jpg' ;
import '../Main.css' ;
import './Team.css' ;

const Team = () => {
	return (
		<div className='outerContainer'>
			<Header/>
			<div className='innerContainer'>
				<h1>IDLmeals Team</h1>
				<table>
				<tr>
					<td valign='top'><Link to="https://www.linkedin.com/in/michael-dicenso-a479b16/"><img src={michaelDiCensoImage} alt="Michael DiCenso"  style={{ width: '200px', height: '200px', margin: ' 10px 0 50px 0' }}/></Link></td>
					<td width='100%'>
					<h2>Michael DiCenso</h2>
					<h3>Chief Executive Officer</h3>
					<p>Michael DiCenso is a High-achieving, passionate, visionary strategic leader with extensive experience delivering excellent outcomes for Institutional Retirement Plan, MEP's/ PEP's, Wealth Management, Non-Qualified Plan, IRAs, Worksite/ Voluntary products, HSAs, Debt Management, Partnership Development, Mergers and Acquisitions and the Investment Advisory businesses.</p>
					<Link to="https://www.linkedin.com/in/michael-dicenso-a479b16/"><img src={linkedInLogo} alt="LinkedIn"  style={{ width: '50px', height: '50px', margin: ' 10px 0 50px 0' }}/></Link>
					</td>
				</tr>
				<tr>
					<td valign='top'><Link to="https://www.linkedin.com/in/samantha-davis-844a1b31a/"><img src={samanthaDavisImage} alt="Samantha Davis"  style={{ width: '200px', height: '200px', margin: ' 10px 0 50px 0' }}/></Link></td>
					<td width='100%'>
					<h2>Samantha Davis MS, RD, LD</h2>
					<h3>Chief Nutrition Officer</h3>
					<p>Professor of Nutrition at TCU.</p>
					<Link to="https://www.linkedin.com/in/samantha-davis-844a1b31a/"><img src={linkedInLogo} alt="LinkedIn"  style={{ width: '50px', height: '50px', margin: ' 10px 0 50px 0' }}/></Link>
					</td>
				</tr>
				<tr>
					<td valign='top'><Link to="https://www.linkedin.com/in/ipser/"><img src={edIpserImage} alt="Edward A. Ipser, Jr."  style={{ width: '200px', height: '200px', margin: ' 10px 0 50px 0' }}/></Link></td>
					<td width='100%'>
					<h2>Edward A. Ipser, Jr., PhD</h2>
					<h3>Chief Technology Officer</h3>
					<p>Edward A. Ipser, Jr., PhD is President of IpserLab LLC, a startup foundry and Managing Partner at Coactify Inc., an innovation consultancy as well as adjunct faculty at TCU. He has founded, cofounded, joined, and advised many startups. As the founder of MarketFusion he raised $23M in venture capital. He also founded the Silicon Valley Association of Software Entrepreneurs and the Fort Worth Innovators Forum. He graduated from TCU with majors in Math, Psychology, and Economics and received a PhD in computer science from USC.</p>
					<Link to="https://www.linkedin.com/in/ipser/"><img src={linkedInLogo} alt="LinkedIn"  style={{ width: '50px', height: '50px', margin: ' 10px 0 50px 0' }}/></Link>
					</td>
				</tr>
				</table>
				<Footer/>
			</div>
		</div>
	) ;
} ;

export default Team;
